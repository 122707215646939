import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import Dropzone from 'react-dropzone';
import filesize from 'filesize';
import { Backup, GetApp, Close } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Template from '../../components/Template/Template';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertInfo } from '../../components/Alert/Alert';
import { removeMask } from '../../services/displayValue';
import api, { speed, spededURL } from '../../services/api';
import { DropContainer, UploadMsg, ListContainer, FileInfo, Preview, TxtInfo } from './Styled';
import a1 from './a1.jpg';

const Certificado = () => {
    const [openLoad, setOpenLoad] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [senha, setSenha] = useState('');
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const { cnpj, id } = infoEmpresa;

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    async function loadData() {
        setOpenLoad(true);
        const response = await api.get(`/configs/listDadosFiscais/${id}`);
        console.log(response);
        if(response.status === 200){
            if(response.data.upload_a1 && Number(response.data.upload_a1) === 1){
                uploadSuccess(response.data.validade);
            }
        }
        setOpenLoad(false);
    }

    function handleUpload(files) {
        const infoFile = files.map(file => ({
            file,
            name: file.name,
            size: filesize(file.size),
            uploaded: false,
            url: null
        }));

        setUploadedFiles(infoFile);
    }

    function renderDragMessage(isDragActive, isDragReject){
        if(!isDragActive){
            return (
                <UploadMsg>
                    <p>Clique ou arraste seu certificado aqui</p><br/>
                    <Backup size={25} color="#c0c0c0"/>
                </UploadMsg>
            );
        }

        if(isDragReject){
            return (
                <UploadMsg style={{backgroundColor: '#e57878'}}>
                    <p>Arquivo Invalido!</p><br/>
                    <Close size={25} color="#c0c0c0"/>
                </UploadMsg>
            );
        }

        return (
            <UploadMsg style={{backgroundColor: '#78e5d5'}}>
                <p>Solte o arquivo!</p><br/>
                <Backup size={25}/>
            </UploadMsg>
        );
    }

    async function processUpload(uploadedFile) {
        setOpenLoad(true);
        const data = new FormData();
        data.append('file', uploadedFile.file, uploadedFile.name);
        data.append('cnpj', cnpj);
        data.append('senha', senha);

        const response = await speed.post('config/addCertificado', data);
        if(response.data.error){
            console.log(response);
            AlertInfo('error', response.data.error, '');
        }else if(response.data.validade){
            const dataCert = {
                uploadA1: 1,
                senha,
                validade: response.data.validade.date
            };
            const resp = await api.post(`configs/addCertificado/${id}`, qs.stringify(dataCert));
            if(resp.status === 200){
                uploadSuccess(resp.data);
                const newDadosFiscais = { upload_a1: 1, senha, validade: dataCert.validade };
                sessionStorage.setItem('dadosFiscais', JSON.stringify(newDadosFiscais));
                AlertInfo('success', 'Dados gravados com sucesso!', '');
            }else{
                AlertInfo('error', 'Falha no processo!', '');
                console.log(resp);
            }
        }
        setOpenLoad(false);
    }

    function uploadSuccess(validadeCert) {
		let cnpjLimpo = removeMask(cnpj);
        let dateCert = new Date(validadeCert);

        const infoFile = [{
            name: 'Certificado Salvo!',
            size: `Valido até: ${Intl.DateTimeFormat('pt-br').format(dateCert)}`,
            uploaded: true,
            url: `${spededURL}/config/dowloadCertificate/${id}/${cnpjLimpo}`
        }];

        setUploadedFiles(infoFile);
    }

    return(
        <Template menuActive="certificado">
            <Dropzone accept="application/x-pkcs12" onDropAccepted={handleUpload}>
                { ({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                    <DropContainer { ...getRootProps() }>
                        <input { ...getInputProps() } />
                        { renderDragMessage(isDragActive, isDragReject) }
                    </DropContainer>
                ) }
            </Dropzone>
            { !!uploadedFiles.length && (
                <ListContainer>
                    { uploadedFiles.map((file, index) => (
                        <li key={index}>
                            <FileInfo>
                                <Preview src={a1} />
                                <TxtInfo>
                                    <strong>
                                        { file.name }
                                    </strong>
                                    <strong>
                                        {file.size}
                                    </strong>
                                </TxtInfo>
                            </FileInfo>
                            <div>
                                {!file.uploaded && (
                                    <div>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Senha"
                                            type="password"
                                            value={senha}
                                            onChange={e => setSenha(e.target.value)}
                                        />
                                        <Button  
                                            fullWidth
                                            variant="contained" 
                                            color="primary" 
                                            className="btn-lg"
                                            onClick={() => { processUpload(file) }}
                                        >
                                            ENVIAR
                                        </Button>
                                    </div>
                                )}
                                {file.url && (
                                    <a href={file.url} target="_blank" rel="noopener noreferrer">
                                        <Tooltip title="Baixar Certificado">
                                            <GetApp color="primary" />
                                        </Tooltip>
                                    </a>
                                )}
                            </div>
                        </li>
                    )) }
                </ListContainer>
            ) }
            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}

export default Certificado;